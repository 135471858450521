import { createSignal, Show } from "solid-js"

const CookiePolicyBanner = () => {
  const [accept, setAccept] = createSignal(
    localStorage?.getItem("sc_tech_accepted_policy") === "true"
  )

  return (
    <Show when={!accept()} fallback={null}>
      <div class="text-sm fixed bg-gray-300 dark:bg-gray-1000 h-28 lg:h-12 justify-center  bottom-0 w-screen left-0 right-0 z-20 p-4 flex flex-col lg:flex-row text-gray-700 dark:text-gray-400">
        <div>
          <div>
            By continuing to use my site, you agree to the use of cookies as
            stated in my privacy policy.
          </div>
        </div>
        <div class="mt-2 lg:mt-0">
          <button
            class="dark:text-indigo-500 text-indigo-800 font-bold lg:ml-4"
            onClick={() => {
              localStorage.setItem("sc_tech_accepted_policy", "true")
              setAccept(true)
            }}
          >
            Accept
          </button>
          <a
            class="dark:text-indigo-500 text-indigo-800 pt-3 font-bold underline underline-offset-[6px] ml-4"
            aria-label="Link to privacy policy page"
            href="/privacy"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </Show>
  )
}

export default CookiePolicyBanner
